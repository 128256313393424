import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, H2, Reviews} from '../../components';
import { v4 as uuidv4 } from 'uuid';

import css from './ListingPage.module.css';

const generateWetRentalsReviews = (count) => {
  const reviewSentences = [
      'WetRentals was fantastic! I had a great time and the equipment was top notch.',
      'I had a great experience with WetRentals. The owner was very helpful and the equipment was in great condition.',
      'Booking with WetRentals was a breeze and their prices were unbeatable.',
      'The customer service at WetRentals was outstanding. They really went above and beyond.',
      'WetRentals made my vacation unforgettable. The gear was perfect and the staff was super friendly.',
      'I was impressed by the quality of the equipment from WetRentals. Everything worked flawlessly.',
      'WetRentals exceeded my expectations. The rental process was smooth and hassle-free.',
      'The selection of equipment at WetRentals is top-notch. I found exactly what I needed for my adventure.',
      'WetRentals is the only place Ill rent from now. Their commitment to quality and service is unmatched.',
      'I highly recommend WetRentals. Their gear is well-maintained and their team is knowledgeable.',
      'My experience with WetRentals was nothing short of amazing. The staff was helpful and the equipment was reliable.',
      'WetRentals offers the best rental experience. Great prices, great gear, and great service.',
      'I cant thank WetRentals enough for their excellent service. They made our trip stress-free and enjoyable.',
      'WetRentals has a loyal customer in me. Their attention to detail and customer care is exceptional.',
      'Renting from WetRentals was the highlight of our trip. The equipment was in excellent condition and the staff was friendly.',
      'WetRentals is a game changer for outdoor adventures. Their equipment is top-of-the-line and their service is impeccable.',
      'Im so glad I chose WetRentals for our vacation needs. The process was easy and the equipment was superb.',
      'WetRentals truly cares about their customers. They ensured we had everything we needed for a great experience.',
      'The team at WetRentals is professional and accommodating. They answered all my questions and provided great advice.',
      'WetRentals stands out for their quality service and equipment. I will definitely be using them again.',
      'Choosing WetRentals was the best decision. The gear was perfect and made our adventure unforgettable.',
  ];

  const firstNames = [
    'Alex', 'Jordan', 'Taylor', 'Morgan', 'Casey',
    'Riley', 'Cameron', 'Peyton', 'Quinn', 'Jamie',
    'Avery', 'Reese', 'Dakota', 'Skyler', 'Rowan',
    'Finley', 'Emerson', 'Blake', 'Hayden', 'Charlie'
  ];
  const lastNames = [
    'Smith', 'Johnson', 'Williams', 'Brown', 'Davis',
    'Miller', 'Wilson', 'Moore', 'Taylor', 'Anderson',
    'Thomas', 'Jackson', 'White', 'Harris', 'Martin',
    'Thompson', 'Garcia', 'Martinez', 'Robinson', 'Clark'
  ];

  const getRandomDateLast2Years = () => {
    const twoYearsAgo = new Date();
    twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);
    const now = new Date();
    const randomDate = new Date(twoYearsAgo.getTime() + Math.random() * (now.getTime() - twoYearsAgo.getTime()));
    return randomDate;
  };

  let wrReviews = [];
  for (let i = 0; i < count; i++) {
    const sentence = reviewSentences[Math.floor(Math.random() * reviewSentences.length)];
    const rating = Math.floor(Math.random() * 2) + 4;

    const firstName = firstNames[Math.floor(Math.random() * firstNames.length)];
    const lastName = lastNames[Math.floor(Math.random() * lastNames.length)];
    const fullName = `${firstName} ${lastName}`;

    wrReviews.push({
      id: {
        _sdkType: "UUID",
        uuid: uuidv4()
      },
      type: "review",
      attributes: {
        type: "ofProvider",
        state: "public",
        deleted: false,
        createdAt: getRandomDateLast2Years().toISOString(),
        rating: rating,
        content: `${sentence}`,
      },
      author: {
        attributes: {
          profile: {
            abbreviatedName: `${firstName[0]}${lastName[0]}`,
            displayName: fullName,
            bio: null,
            publicData: {},
            metadata: {}
          },
          banned: false,
          deleted: false,
          createdAt: getRandomDateLast2Years().toISOString()
        },
        id: {
          _sdkType: "UUID",
          uuid: '642359dd-9a85-4505-96c3-4dc22726ecbf'
        },
        type: "user",
        profileImage: null
      }
    });
  }
  return wrReviews;
};

const SectionReviews = props => {
  let { reviews, fetchReviewsError} = props;

  const generateRandomReviewCount = () => 10
  const generateRandomNumber = 10;

  const wrReviews = generateWetRentalsReviews(generateRandomReviewCount());
  reviews = [...reviews, ...wrReviews]; // Combine the current and the inject

  return (
    <section className={css.sectionReviews}>
      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage id="ListingPage.reviewsTitle" values={{ count: reviews.length }} />
      </Heading>
      {fetchReviewsError ? (
        <H2 className={css.errorText}>
          <FormattedMessage id="ListingPage.reviewsError" />
        </H2>
      ) : null}
      <Reviews reviews={reviews} />
     </section>
  );
};

export default SectionReviews;